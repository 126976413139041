import styles from './tweet-loading.module.css'
export default function TweetLoading() {
  return (
    <div className="flex justify-center">
      <div style={{ width: 48 }} className="mr-6">
        <div className={styles.loading}></div>
      </div>
      <div className="text-2xl">Loading</div>
    </div>
  )
}
