export const termsList = [
  {
    text: 'i love',
    color: '#F631A8',
  },
  {
    text: 'i hate',
    color: '#e76f51',
  },
  {
    text: 'i miss',
    color: '#e9c46a',
  },
  {
    text: 'i feel',
    color: '#2a9d8f',
  },
  {
    text: 'i regret',
    color: '#df2935',
  },
]
