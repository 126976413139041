import React, { Component, ErrorInfo, ReactNode } from 'react'

interface Props {
  children: ReactNode
}

interface State {
  error: Error | null
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    error: null,
  }

  public static getDerivedStateFromError(e: Error): State {
    return { error: e }
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo)
  }

  public render() {
    if (this.state.error) {
      console.error(this.state.error)
      return (
        <>
          <h1>Error: {this.state.error.message}</h1>
        </>
      )
    }

    return this.props.children
  }
}

export default ErrorBoundary
