interface Props {
  match: { text: string; color: string }[]
  children: string
}

function htmlDecode(input) {
  var doc = new DOMParser().parseFromString(input, 'text/html')
  return doc.documentElement.textContent || ''
}
export function parse(text: string, term: string | string[]) {
  //https://stackoverflow.com/questions/7124778/how-to-match-anything-up-until-this-sequence-of-characters-in-a-regular-expres
  //Match any characters as few as possible until a "abc" is found, without counting the "abc"

  //https://stackoverflow.com/questions/1232220/how-to-non-greedy-multiple-lookbehind-matches
  //(?:.*) will gobble as much as it can, only the minimum will be matched by the rest of the pattern - effectively making the rest non-greedy

  const ellipsis = '…'
  const terminators = `\\p{Extended_Pictographic}\\n.!?${ellipsis}`
  const termRegex = ([] as any).concat(term).join('|')
  const reg = `(?:.*)(^|[${terminators}])(.*)\\b(${termRegex})\\b($|[^${terminators}]*.?)`
  //https://regex101.com/r/8qwk23/1
  const matcher = new RegExp(reg, 'iu')
  // console.log(matcher.source)
  const m = text.match(matcher)
  if (!m || !m[4] || !m[4].trim()) return null
  return {
    prefix: (m[2] || '').trim(),
    term: m[3],
    rest: (m[4] || '').trim(),
  }
}

export default function Tweet(props: Props) {
  const termRegex = props.match.map((t) => t.text)
  const decoded = htmlDecode(props.children)
  const p = parse(decoded, termRegex)
  if (p === null) {
    // console.warn('Not the right parse format', props.children, p)
    return null
  }
  const { prefix, term, rest } = p
  const color = props.match.find(
    (t) => t.text.toLowerCase() === term.trim().toLowerCase(),
  )?.color
  return (
    <div title={decoded} className="font-sans">
      <span className="text-slate-500">{prefix}</span>
      <div>
        <span
          style={{ color }}
          className={`highlight text-5xl lowercase font-bold tracking-tighter`}
        >
          {term}{' '}
        </span>
        <span className={`text-2xl`}>{rest}</span>
      </div>
      {/* <div className="text-xs">{props.children}</div> */}
    </div>
  )
}
