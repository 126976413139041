import * as Types from '../types'

export function mergeResponses(
  results: [Types.TwitterReponse],
): Types.TwitterReponse | PromiseLike<Types.TwitterReponse> {
  return results.reduce(
    (accum, val) => {
      const mergedData = [...accum.data, ...val.data].sort(function (a, b) {
        return +new Date(b.created_at) - +new Date(a.created_at)
      })

      return {
        data: mergedData,
        meta: {
          ...accum.meta,
          ...val.meta,
        },
        location: val.location,
      }
    },
    { data: [], meta: {}, location: undefined } as Types.TwitterReponse,
  )
}

export async function makeQuery(
  terms: string[],
  location: Types.LatLng | null,
): Promise<Types.TwitterReponse> {
  const qs = `"${terms.join('" OR "')}"`
  let latLng =
    location?.lat && location.lng
      ? `&lat=${location?.lat}&lng=${location?.lng}`
      : ''
  const maxResults = 80
  const res = await fetch(
    // `http://127.0.0.1:8787/api/terms?term=${qs}${latLng}&maxResults=${maxResults}`,
    `https://feelings.earth/api/terms?term=${qs}${latLng}&maxResults=${maxResults}`,
  )
  const json = await res.json()
  if (!res.ok) {
    return Promise.reject(json)
  }
  return json
}
