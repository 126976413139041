import { lazy, Suspense, useRef, useState } from 'react'
import { useEffect } from 'react'

import GlobeLoader from 'components/globe/GlobeLoading'
import TweetList from 'components/tweets/TweetList'

import * as Types from 'types'
import TweetLoading from 'components/tweets/TweetLoading'
import { makeQuery } from 'components/api-utils'
import { termsList } from '../constants'
import ErrorBoundary from 'components/ErrorBoundary'

const SettingsPanel = lazy(
  () => import('components/settings-panel/SettingsPanel'),
)

const TweetGlobe = import.meta.env.SSR
  ? lazy(() => import('components/globe/GlobeLoading'))
  : lazy(() => import('components/globe/Globe'))

export default function Index(p) {
  const [error, updateError] = useState<string | false>(false)
  const [tweets, setTweets] = useState<Types.TwitterReponse['data'] | null>(
    null,
  )
  const [latlng, setLatlng] = useState<Types.LatLng | null>(null)
  const { lat, lng } = latlng || {}

  const isAutoTriggeredFetch = useRef(false)
  useEffect(() => {
    // console.log('use effect')
    updateError(false)
    if (isAutoTriggeredFetch.current === true) {
      isAutoTriggeredFetch.current = false
      return
    }
    makeQuery(
      termsList.map((t) => t.text).slice(0),
      lat && lng ? { lat, lng } : null,
    )
      .then((d) => {
        if (!lat && d.location) {
          isAutoTriggeredFetch.current = true
          setLatlng(d.location) //only need to trust server for initial location
        }
        setTweets(d.data)
        updateError(false)
      })
      .catch((e) => {
        updateError(e.message || 'Unknown error')
        console.error('server error', e)
      })
  }, [lat, lng])

  const [isPaused, setIsPaused] = useState(false)
  const [isLightTheme, setLightTheme] = useState(false)
  const [isPartyMode, setPartyMode] = useState(false)

  function toggleFullScreen() {
    if (document.fullscreenElement) {
      document.exitFullscreen && document.exitFullscreen()
    } else {
      const root = document.querySelector('html')
      root && root.requestFullscreen && root.requestFullscreen()
    }
  }

  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const key = e.key.toLowerCase()
      if (key === 'p') {
        setIsPaused(!isPaused)
      } else if (key === 't') {
        setLightTheme(!isLightTheme)
      } else if (key === '*') {
        setPartyMode(!isPartyMode)
      } else if (key === 'f') {
        toggleFullScreen()
      }
    }
    document.addEventListener('keypress', listener)
    return () => {
      document.removeEventListener('keypress', listener)
    }
  })

  useEffect(() => {
    if (isLightTheme) {
      document.body.classList.add('light')
    } else {
      document.body.classList.remove('light')
    }
  }, [isLightTheme])

  useEffect(() => {
    if (isPartyMode) {
      document.body.classList.add('party')
    } else {
      document.body.classList.remove('party')
    }
  }, [isPartyMode])
  const [width, setWidth] = useState<number>(
    import.meta.env.SSR ? 0 : window.innerWidth,
  )
  useEffect(() => {
    function handleWindowSizeChange() {
      setWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowSizeChange)
    return () => {
      window.removeEventListener('resize', handleWindowSizeChange)
    }
  }, [])
  const isMobile = width <= 768

  useEffect(() => {
    function handleVisibilityChange() {
      setIsPaused(document.visibilityState === 'hidden')
    }
    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  const isLoading = !tweets && !error
  // const isLoading = true
  return (
    <div
      className={`mx-auto text-slate-${
        isLightTheme ? '500' : '400'
      } flex min-h-screen`}
    >
      <div className="basis-3/5 hidden md:block">
        {!isMobile && (
          <Suspense
            fallback={
              <div className=" flex justify-center items-center min-h-full">
                <GlobeLoader />
              </div>
            }
          >
            <ErrorBoundary>
              <TweetGlobe
                onLocationChange={(latLng) => {
                  setTweets(null)
                  setLatlng(latLng)
                }}
                location={latlng}
                isLightTheme={isLightTheme}
              ></TweetGlobe>
            </ErrorBoundary>
          </Suspense>
        )}
      </div>
      <div className="my-auto pl-6 md:w-[60rem] xl:w-[80rem] md:pl-0 pr-6 md:pr-12 lg:pr-16 pt-12 tweets">
        <ErrorBoundary>
          {isLoading ? (
            <TweetLoading />
          ) : error ? (
            <h2>{error}</h2>
          ) : tweets && tweets.length ? (
            <TweetList
              isPaused={isPaused}
              match={termsList}
              tweets={tweets}
              locationKey={`${lat}${lng}`}
            />
          ) : (
            <div>No tweets for current location</div>
          )}
        </ErrorBoundary>
      </div>
      {!isMobile && (
        <Suspense fallback={null}>
          <div className="fixed bottom-4 left-6 flex">
            <SettingsPanel isLightTheme={isLightTheme} isPaused={isPaused} />
          </div>
        </Suspense>
      )}
    </div>
  )
}
