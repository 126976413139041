import { useState } from 'react'
import * as Types from 'types'

import useInterval from 'hooks/use-interval'
import Tweet from './Tweet'
import styles from './tweet-list.module.css'

interface Props {
  match: { text: string; color: string }[]
  tweets: Types.Tweet[]
  locationKey: string
  isPaused: boolean
}

export default function TweetList(props: Props) {
  const { tweets, isPaused } = props

  const INITIAL_TWEETS_TO_SHOW = 8

  const maxTweets = Math.ceil(document.body.clientHeight / 100) //doesn't need observer because getting called every setinterval anyway
  const [shownTweets, setShownTweets] = useState(INITIAL_TWEETS_TO_SHOW)
  const [lastAdded, updateLastAdded] = useState<string | null>(null)

  const noMoreLeft = tweets.length && lastAdded === tweets[0].id
  const fetchInterval = noMoreLeft || isPaused ? null : 5000

  useInterval(() => {
    tweets.length &&
      updateLastAdded(() => tweets[tweets.length - shownTweets - 1]?.id)
    setShownTweets(() => shownTweets + 1)
  }, fetchInterval)

  const startIndex = tweets.length - shownTweets
  const tweetsToShow = tweets.slice(startIndex, startIndex + maxTweets)
  function getClass(id: string, index: number) {
    if (lastAdded === null) {
      return styles.load
    }
    if (index === maxTweets - 1) return styles.hide
    if (id === lastAdded) return styles.show
    return ''
  }
  const lis = tweetsToShow.map((d, i) => {
    const style =
      lastAdded === null
        ? ({
            '--animation-order': tweetsToShow.length - i - 1,
          } as React.CSSProperties)
        : undefined

    return (
      <li
        key={d.id}
        className={`mb-6 ${getClass(d.id, i)} ${styles.tweet}`}
        style={style}
      >
        <Tweet match={props.match}>{d.text}</Tweet>
      </li>
    )
  })
  return <ul>{lis}</ul>
}
